import { useEffect, useState } from "react";
import "./styles.css";

import icon from "../../assets/iconwhats.png";

const CtaWpp = (props) => {
  const [text, setText] = useState("Solicite um orçamento");

  useEffect(() => {
    if (props.text !== undefined) setText(props.text);
  }, [props.text]);

  return (
    <button id="ctaWpp">
      <a
        href="http://wa.me/+5541985038851?text=Olá,%20gostaria%20de%20fazer%20um%20orçamento%20do%20serviço%20de%20desentupimento"
        target="_blank"
        rel="noreferrer"
      >
        <figure>
          <img src={icon} alt="Ícone do Whatsapp" />
        </figure>

        <p>{text}</p>
      </a>
    </button>
  );
};

export default CtaWpp;
