import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "./styles.css";

import CtaTelWpp from "../../components/CTATelWpp";
import CtaWpp from "../../components/CTAWpp";
import FormasPagamento from "../../components/FormasPagamento";

import about1_1 from "../../assets/about1_1.png";
import about1_2 from "../../assets/about1_2.png";
import about1_3 from "../../assets/about1_3.png";
import about1_4 from "../../assets/about1_4.png";
import about1_5 from "../../assets/about1_5.png";
import about1_6 from "../../assets/about1_6.png";
import about2_1 from "../../assets/about2_1.png";
import about2_2 from "../../assets/about2_2.png";
import about2_3 from "../../assets/about2_3.png";
import about2_4 from "../../assets/about2_4.png";

const data = [
  {
    image: about1_1,
    title: "Desentupimento de pias",
    text: "Desentupimento de pias realizado com rapidez e eficiência por profissionais qualificados, garantindo o perfeito funcionamento de suas instalações.",
  },
  {
    image: about1_2,
    title: "Desentupimento de vaso sanitário",
    text: "Desentupimento de vaso sanitário com eficiência e rapidez, garantindo sua total satisfação e conforto.",
  },
  {
    image: about1_3,
    title: "Desentupimento de esgoto",
    text: "Desentupimento de esgoto com eficácia e rapidez, proporcionando soluções confiáveis e duradouras para a sua rede de saneamento, com foco na sua comodidade e bem-estar.",
  },
  {
    image: about1_4,
    title: "Desentupimento e limpeza de caixa de gordura",
    text: "Excelência em desentupimento de caixa de gordura oferece diagnóstico preciso, atendimento 24h, equipe qualificada e equipamentos modernos, garantindo eficiência e satisfação do cliente.",
  },
  {
    image: about1_5,
    title: "Desentupimento de rede de água pluvial",
    text: "Resolvemos obstruções de redes de água pluvial de forma eficiente, garantindo escoamento perfeito e evitando inundações, com atendimento ágil e especializado.",
  },
  // {
  //   image: about1_6,
  //   title: "Limpeza de fossas (sucção)",
  //   text: "Realizamos o serviço de limpeza de fossas  com o processo de sucção dos dejetos, de forma que evite o desconforto o mal cheiro  em seu lar ou empresa.",
  // },
];

const About = () => {
  const isMobile =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: true,
  };

  return (
    <section id="about">
      <article className="about1">
        <div className="container vertical">
          <h2 className="title">NOSSOS SERVIÇOS</h2>

          {isMobile ? (
            <div className="mobile">
              {data.map(({ image, title, text }, index) => (
                <div className="item" key={index}>
                  <figure>
                    <img src={image} alt={`Slide ${index}`} />
                  </figure>

                  <div className="bottom">
                    <p className="item_title">{title}</p>

                    <p className="item_text">{text}</p>

                    <CtaWpp />
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="carousel">
              <Slider {...settings}>
                {data.map(({ image, title, text }, index) => (
                  <div className="item" key={index}>
                    <figure>
                      <img src={image} alt={`Slide ${index}`} />
                    </figure>

                    <div className="bottom">
                      <p className="item_title">{title}</p>

                      <p className="item_text">{text}</p>

                      <CtaWpp />
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          )}
        </div>
      </article>

      <article className="about2">
        <div className="container vertical">
          <h2 className="title">NOSSO DIFERENCIAL</h2>

          <p className="text">
            É um prazer poder oferecer o melhor serviços aos nossos clientes!
          </p>

          <div className="gallery">
            <figure>
              <img src={about2_1} alt="Pontualidade" />
            </figure>

            <figure>
              <img src={about2_2} alt="Atendimento 24h" />
            </figure>

            <figure>
              <img src={about2_3} alt="Profissionalismo" />
            </figure>

            <figure>
              <img src={about2_4} alt="Atendimento personalizado" />
            </figure>
          </div>

          <p className="text">Ligue agora ou fale conosco via WhatsApp:</p>

          <CtaTelWpp />
        </div>
      </article>

      <FormasPagamento />
    </section>
  );
};

export default About;
