import "./styles.css";

import CtaWpp from "../../components/CTAWpp";
import CtaTelWpp from "../../components/CTATelWpp";

import logo_topo from "../../assets/logo_topo.png";
import header2 from "../../assets/header2.png";

const Header = () => {
  return (
    <header id="header">
      <section className="header1">
        <article className="container">
          <figure className="top">
            <img src={logo_topo} alt="Logo" />
          </figure>

          <h1 className="title">Empresa Especializada em Desentupimento</h1>

          <p className="text">
            Trabalhamos com desentupimento de pias, vasos sanitários, esgoto,
            rede de água pluvial, além de limpeza de fossas e caixas de gordura.
          </p>

          <CtaWpp />
        </article>
      </section>

      <section className="header2">
        <article className="container">
          <figure>
            <img src={header2} alt="ATENDIMENTO 24h" />
          </figure>

          <h2 className="title">ATENDIMENTO 24h</h2>
        </article>
      </section>

      <section className="header3">
        <article className="container vertical">
          <h2 className="title">
            ATENDEMOS EMPRESAS, RESIDÊNCIAS, CONDOMÍNIOS E APARTAMENTOS
          </h2>

          <p className="text">
            Nossa equipe de profissionais qualificados é comprometida com a
            entrega de um serviços de qualidade, com agilidade e excelência!
            Estamos prontos para lhe atender.
          </p>

          <p className="text2">SOLICITE AGORA MESMO O SEU ORÇAMENTO:</p>

          <CtaTelWpp />
        </article>
      </section>
    </header>
  );
};

export default Header;
